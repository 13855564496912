import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { useForm } from "react-hook-form";
import Spinner from 'react-bootstrap/Spinner';
import { ToastContainer, toast } from 'react-toastify';
import { Helmet } from "react-helmet";
import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
const Profile = () => {
    const [showPassword, setShowPassword] = useState(false);
    const [adminProfile, setAdminProfile] = useState([]);
    const [id, setId] = useState('');
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [pending, setPending] = useState(true);
    const [password, setPassword] = useState('');


    const getData = () => {
        axios.get("/getadminProfile").then((response) => {
            setPending(false);
            setAdminProfile(response.data.data)
        }).catch((err) => {
            setPending(false);
            console.log(err);
        })
    }

    useEffect(() => {
        getData();
    }, []);

    useEffect(() => {

        adminProfile?.map((admin) => {
            setName(admin.name);
            setEmail(admin.email);
            setPassword(admin.password);
            setId(admin.id);

        })
    }, [adminProfile]);

    const notify = () => toast.info("Profile Update SuccessFully", { position: "top-center" });

    const { register, handleSubmit, formState: { errors } } = useForm();
    const onSubmit = () => {
        setPending(true);
        axios.post('/updateadminProfile', {
            id: 1,
            name: name,
            email: email,
            password: password,
           

        }, {
            method: 'POST',
            headers: {
                "Content-Type": "multipart/form-data"
            }

        }).then((response) => {
            if (response.status === 200) {
                notify();
                setPending(false);

            }

        }).catch((err) => {
            setPending(false);
            console.log(err);
        })
    }


    return (
        <>

            <Helmet>
                <title>KPHE | Profile</title>
            </Helmet>

            <Spinner animation="border" className={pending ? 'mySpinner' : 'd-none'} />
            <section id="main-content" style={pending ? { opacity: '0.5' } : { opacity: '1' }}>
                <section className="wrapper _profile">
                    <div className="row">
                    <ToastContainer />
                        <div className="col-lg-12">
                            <span className='p-3'>Profile</span>
                            <section className="card m-3">

                                <div className="card-body">

                                    <form className="row g-3" onSubmit={handleSubmit(onSubmit)}>
                                        <div className="col-md-6">
                                            <label className="form-label">Name</label>
                                            <input type="name" className="form-control" id="inputname"
                                                value={name}
                                                {...register("Name", {
                                                    required: false,
                                                    onChange: (e) => setName(e.target.value)
                                                })} />
                                            {errors.Name && <div className='errMsg'>Please Enter Admin Name</div>}
                                        </div>
                                        <div className="col-md-6">
                                            <label className="form-label">Email</label>
                                            <input type="email" className="form-control" id="inputEmail4"
                                                value={email}


                                                {...register("Email", {
                                                    required: false,
                                                    onChange: (e) => setEmail(e.target.value)
                                                })} />
                                            {errors.Email && <div className='errMsg'>Please Enter Admin Email</div>}
                                        </div>
                  
                                       
                                       
                                        <Form.Group className="mb-3 col-md-6" >
                                            <Form.Label>Update Password</Form.Label>
                                            <InputGroup>

                                                <Form.Control type={showPassword ? "text" : "password"} placeholder='Enter Password' autocomplete="new-password"  {...register("password", { required: false, onChange: (e) => setPassword(e.target.value) })} />
                                                <InputGroup.Text style={{ cursor: 'pointer' }} onClick={() => setShowPassword(!showPassword)}>{showPassword ? <FaEyeSlash />  : <FaEye />}</InputGroup.Text>

                                            </InputGroup>
                                        </Form.Group>
                                       
                                        <div className="col-12 text-center">
                                            <Button type="submit" className='Add_btn_'>Update</Button>
                                        </div>
                                    </form>
                                </div>
                            </section>
                        </div>
                    </div>
                </section>
            </section>
        </>
    )
}

export default Profile;