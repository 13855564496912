import React from "react";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";

export default function ProtectedRoute({ children }) {

  const { adminAuth, auth } = useSelector((state) => state?.auth);

  if (!adminAuth && !auth) {
    return (
      <>
        <Navigate to={"/"} replace />
      </>
    );
  }
  return children;
}