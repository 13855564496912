import React, { useState, useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import { useForm } from "react-hook-form";
import { Button } from 'react-bootstrap';
import Spinner from 'react-bootstrap/Spinner';
import TextEditor from '../Editor/TextEditor';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import ProductImage from '../Assets/default.png';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const AddProducts = () => {

    const [pending, setPending] = useState(false);

    const [name, setServicename] = useState('');
    const [title, setServicetitle] = useState('');
    const [short_description, setServiceshort] = useState('');
    const [description, setServicedescription] = useState('');
    const [image, setServiceimage] = useState('');
    const [banner_title, setSerivebannertitle] = useState('');
    const [banner_description, setSerivebannerdescription] = useState('');
    const [banner_image, setSerivebannerimage] = useState('');
    const [list_title, setListtitle] = useState('');
    const [list_description, setListdescription] = useState('');



    const [updatedImage, setUpdatedImage] = useState('');


    const [seoTitle, setSeoTitle] = useState('');
    const [Description, setDescription] = useState('');
    const [Canonical, setCanonical] = useState('');
    const [Robot, setRobot] = useState('');
    const [Script, setScript] = useState('');
    const [Feature, setFeature] = useState('');

    const notify = () => toast.info("Service Added SuccessFully", { position: "top-center" });

    const navigate = useNavigate();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])



    const { register, handleSubmit, formState: { errors } } = useForm();
    const onSubmit = () => {
        setPending(true);
        const formData = new FormData();
        formData.append("file", updatedImage);

        axios.post('/addService', {
            name: name,
            title: title,
            short_description: short_description,
            description: description,
            image: image,
            banner_title: banner_title,
            banner_description: banner_description,
            banner_image: banner_image,
            list_title: list_title,
            list_description: list_description,

            seo_title: seoTitle,
            seo_description: Description,
            canonical: Canonical,
            robot: Robot,
            script: Script,
            feature_image: Feature

        }, {
            method: 'POST',
            headers: {
                "Content-Type": "multipart/form-data"
            }
        }).then((response) => {
            if (response.status === 200) {
                notify();
                setPending(false);
                setTimeout(() => {
                    navigate('/service');
                }, 2000);

            }

        }).catch((err) => {
            setPending(false);
            console.log(err);
        })


    }

    useEffect(() => {

    }, []);


    return (

        <>

            <Helmet>
                <title>KPHE | Services</title>
            </Helmet>

            <Spinner animation="border" className={pending ? 'mySpinner' : 'd-none'} />
            <section id="main-content" style={pending ? { opacity: '0.5' } : { opacity: '1' }}>
                <section class="wrapper">
                    <div class="row">
                        <ToastContainer />
                        <div class="col-lg-12">
                            <span>Add Service</span>
                            <section class="card cardSectionBorder mt-3">
                                <div class="card-body">
                                    <Form className='row' onSubmit={handleSubmit(onSubmit)}>
                                        <Form.Group className="col-md-6 pb-5">
                                            <Form.Label>Service Name</Form.Label>
                                            <Form.Control type="text"
                                                value={name}
                                                {...register("name", {
                                                    required: true,
                                                    onChange: (e) => setServicename(e.target.value)
                                                })}
                                            />
                                            {errors.name && <div className='errMsg pt-2'>Please Enter Service Name</div>}
                                        </Form.Group>
                                        <Form.Group className="col-md-6 pb-5">
                                            <Form.Label>Content Title</Form.Label>
                                            <Form.Control type="text"
                                                value={title}
                                                {...register("Title", {
                                                    required: true,
                                                    onChange: (e) => setServicetitle(e.target.value)
                                                })}
                                            />
                                            {errors.Title && <div className='errMsg pt-2'>Please Enter Service Title</div>}
                                        </Form.Group>


                                        <div className='col-lg-12 pb-5'>
                                            <Form.Label>Service Content Short Description</Form.Label>
                                            <TextEditor data={short_description} setUpdatedContent={setServiceshort}

                                            />
                                            {errors.ProductContentTitle && <div className='errMsg pt-2'>Please Provide Short Description</div>}
                                        </div>
                                        <div className='col-lg-12 pb-5'>
                                            <Form.Label>Services Content Long Description</Form.Label>
                                            <TextEditor data={description} setUpdatedContent={setServicedescription} />
                                        </div>

                                        <Form.Group className="col-md-6 pb-5">
                                            <Form.Label>List Title</Form.Label>
                                            <Form.Control type="text"
                                                value={list_title}
                                                {...register("list_title", {
                                                    onChange: (e) => setListtitle(e.target.value)
                                                })}
                                            />
                                        </Form.Group>

                                        <Form.Group className="col-md-6 pb-5">
                                            <Form.Label>List Description</Form.Label>
                                            <Form.Control type="text"
                                                value={list_description}
                                                {...register("list_description", {
                                                    onChange: (e) => setListdescription(e.target.value)
                                                })}
                                            />
                                        </Form.Group>

                                        <Form.Group className="col-md-6 pb-5">
                                            <Form.Label>Service Image</Form.Label>
                                            <Form.Control type="file"

                                                {...register("image", {
                                                    required: true,
                                                    onChange: (e) => setServiceimage(e.target.files[0])
                                                })}

                                            />
                                            {errors.image && <div className='errMsg pt-2'>Please Provide Service Image</div>}

                                        </Form.Group>

                                        <div className='col-lg-6 text-center'>
                                            <Form.Label>Service Image Preview</Form.Label>
                                            <div className='p-3'>

                                                {image == "" ?

                                                    <img src={`${image}`} style={{ maxWidth: "300px", maxHeight: "300px" }} />
                                                    :
                                                    <img src={URL.createObjectURL(image)} style={{ maxWidth: "300px", maxHeight: "300px" }} />

                                                }
                                            </div>
                                        </div>



                                        <Form.Group className="col-md-6 pb-5">
                                            <Form.Label>Banner Title</Form.Label>
                                            <Form.Control type="text"
                                                value={banner_title}
                                                {...register("banner_title", {
                                                    required: true,
                                                    onChange: (e) => setSerivebannertitle(e.target.value)
                                                })}
                                            />
                                            {errors.banner_title && <div className='errMsg pt-2'>Please Enter Banner Title</div>}
                                        </Form.Group>

                                        <Form.Group className="col-md-6 pb-5">
                                            <Form.Label>Banner Description</Form.Label>
                                            <Form.Control type="text"
                                                value={banner_description}
                                                {...register("banner_description", {
                                                    required: true,
                                                    onChange: (e) => setSerivebannerdescription(e.target.value)
                                                })}
                                            />
                                            {errors.banner_description && <div className='errMsg pt-2'>Please Enter Banner Description</div>}
                                        </Form.Group>



                                        <Form.Group className="col-md-6 pb-5">
                                            <Form.Label>Banner Image</Form.Label>
                                            <Form.Control type="file"

                                                {...register("banner_image", {
                                                    required: true,
                                                    onChange: (e) => setSerivebannerimage(e.target.files[0])
                                                })}

                                            />
                                            {errors.banner_image && <div className='errMsg pt-2'>Please Provide Banner Image</div>}

                                        </Form.Group>

                                        <div className='col-lg-6 text-center'>
                                            <Form.Label>Banner Image Preview</Form.Label>
                                            <div className='p-3'>

                                                {banner_image == "" ?

                                                    <img src={`${banner_image}`} style={{ maxWidth: "300px", maxHeight: "300px" }} />
                                                    :
                                                    <img src={URL.createObjectURL(banner_image)} style={{ maxWidth: "300px", maxHeight: "300px" }} />

                                                }
                                            </div>
                                        </div>



                                        <Form.Group className="col-md-12 pb-3">
                                            <Form.Label>Seo Title</Form.Label>
                                            <Form.Control
                                                value={seoTitle} placeholder="Enter Title"
                                                {...register("title", {
                                                    required: true,
                                                    onChange: (e) => setSeoTitle(e.target.value)
                                                })}
                                            />
                                            {errors.title && <div className='errMsg pt-2'>Please Provide Title</div>}
                                        </Form.Group>

                                        <Form.Group className="col-md-12 pb-3">
                                            <Form.Label>Seo Description</Form.Label>
                                            <Form.Control
                                                value={Description} placeholder="Enter Description"
                                                {...register("description", {
                                                    required: true,
                                                    onChange: (e) => setDescription(e.target.value)
                                                })}
                                            />
                                            {errors.description && <div className='errMsg pt-2'>Please Provide Description</div>}
                                        </Form.Group>

                                        <Form.Group className="col-md-12 pb-3">
                                            <Form.Label>Canonical</Form.Label>
                                            <Form.Control
                                                value={Canonical} placeholder="Enter Canonical"
                                                {...register("canonical", {
                                                    required: true,
                                                    onChange: (e) => setCanonical(e.target.value)
                                                })}
                                            />
                                            {errors.canonical && <div className='errMsg pt-2'>Please Provide Canonical</div>}
                                        </Form.Group>


                                        <Form.Group className="col-md-12 pb-3">
                                            <Form.Label>Robot</Form.Label>
                                            <Form.Select aria-label="Default select example"
                                                value={Robot}
                                                {...register("robot", {
                                                    required: true,
                                                    onChange: (e) => setRobot(e.target.value)
                                                })}

                                            >
                                                <option value="">Select Robot</option>
                                                <option value="1">Index/Follow</option>
                                                <option value="2">Index/No-follow</option>
                                                <option value="3">No-index/Follow</option>
                                                <option value="4">No-index,No-Follow</option>
                                            </Form.Select>

                                            {errors.robot && <div className='errMsg pt-2'>Please Select robot</div>}
                                        </Form.Group>

                                        <Form.Group className="col-md-12 pb-3">
                                            <Form.Label>Feature Image url</Form.Label>
                                            <Form.Control
                                                value={Feature} placeholder="Enter Url"
                                                {...register("feature", {
                                                    required: true,
                                                    onChange: (e) => setFeature(e.target.value)
                                                })}
                                            />
                                            {errors.feature && <div className='errMsg pt-2'>Please Provide Image</div>}
                                        </Form.Group>

                                        <Form.Group className="col-md-12 pb-3">
                                            <Form.Label>Script</Form.Label>
                                            <Form.Control
                                                value={Script} placeholder="Enter Script"
                                                {...register("script", {
                                                    required: true,
                                                    onChange: (e) => setScript(e.target.value)
                                                })}
                                            />
                                            {errors.script && <div className='errMsg pt-2'>Please Provide Script</div>}
                                        </Form.Group>



                                        <div className='text-center'>
                                            <Button className='Add_btn_' type='submit'>
                                                Submit
                                            </Button>
                                        </div>
                                    </Form>
                                </div>

                            </section>
                        </div>
                    </div>
                </section>
            </section>
        </>
    )
}

export default AddProducts