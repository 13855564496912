import axios from 'axios';
import React, { useState, useEffect } from 'react'

import { FaTrashAlt, FaEdit } from 'react-icons/fa';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { useForm } from 'react-hook-form';
import Spinner from 'react-bootstrap/Spinner';
import { ToastContainer, toast } from 'react-toastify';
import DataTables from '../DataTable/DataTables';
// import { Helmet } from 'react-helmet';

const SEO = () => {

    // const api_url = process.env.REACT_APP_API_URL;

    const [banner, setBanner] = useState([]);
    const [show, setShow] = useState(false);
    const [showDelete, setDeleteShow] = useState(false);
    const [showUpdate, setShowUpdate] = useState(false);
    const handleUpdate = () => setShowUpdate(false);
    const handleClose = () => {
        setDeleteShow(false);
        setShowUpdate(false);
        setShow(false);
        reset({
            name: "",
            seo_title: "",
            seo_description: "",
            canonical: "",
            robot: "",
            script: "",
            feature_image: "",

        })
    }
    const [pending, setPending] = useState(true);

    const [data, setData] = useState([]);

    // add
    const [pageName, setName] = useState('');


    // update
    const [Id, setId] = useState('');
    const [updatedname, setUpdatedName] = useState('');
    const [updatedTitle, setUpdatedTitle] = useState('');
    const [updatedDescription, setUpdatedDescription] = useState('');
    const [UpdatedCanonical, setUpdatedCanonical] = useState('');
    const [UpdatedRobot, setUpdatedRobot] = useState('');
    const [UpdatedScript, setUpdatedScript] = useState('');
    const [UpdatedFeature, setUpdatedFeature] = useState('');

    const [deleteIds, setDeleteIds] = useState('');

    const getBanners = () => {
        axios.get(`/getAdminseo`).then((response) => {
            setBanner(response.data.data);
            setPending(false);
        }).catch((err) => {
            setPending(false);
            console.log(err);
        })
    }


    const handleShow = () => {
        setShow(true);
        // getProductName();

    };
    const handleDeleteShow = (e) => {
        setDeleteShow(true);
        setDeleteIds(e);

    };

    const handleShowUpdate = (id) => {
        setShowUpdate(true);
        // getProductName();
        axios.get(`/singleseo/${id}`).then((response) => {
            setData(response.data.data);
        }).catch((err) => {

            console.log(err);
        })

    }

    useEffect(() => {
        data?.map((data) => {

            setId(data.id);
            setUpdatedName(data.pageName);
            setUpdatedTitle(data.seo_title);
            setUpdatedDescription(data.seo_description);
            setUpdatedCanonical(data.canonical);
            setUpdatedRobot(data.robot);
            setUpdatedScript(data.script);
            setUpdatedFeature(data.feature_image);

        })

    }, [data])




    const tableData = {
        columns: [
            {
                name: '#',
                // width: '50px',
                cell: (row, index) => index + 1
            },
            {
                name: 'Page Name',
                selector: (row) => row.pageName,
                width: "150px",
            },
            {
                name: 'Title',
                selector: (row) => row.seo_title,
                width: "650px",
            },
            {
                name: "Action",
                selector: row => <div className='d-flex' style={{ gap: '10px' }}>
                    <Button className='Add_btn_' onClick={() => handleShowUpdate(row.id)}><FaEdit /></Button>
                </div>,
                right: true
            },

        ],

    };


    useEffect(() => {
        getBanners();
    }, []);

    const notifyUpdate = () => toast.success('Seo update Successfully', {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
    });


    const { register, handleSubmit, reset, formState: { errors } } = useForm();


    const onUpdate = () => {
        setPending(true);


        axios.post(`/updateseo`, {
            id: Id,
            seo_title: updatedTitle,
            seo_description: updatedDescription,
            canonical: UpdatedCanonical,
            robot: UpdatedRobot,
            script: UpdatedScript,
            feature_image: UpdatedFeature

        }, {
            method: 'POST',
            headers: {
                "Content-Type": "multipart/form-data"
            }
        }).then((response) => {
            if (response.status === 200) {
                notifyUpdate();
                window.location.reload(true);

            }
        }).catch((err) => {

            console.log(err);
        })


    }

    return (
        <>


            <Spinner animation="border" className={pending ? 'mySpinner' : 'd-none'} />
            <section id="main-content" style={pending ? { opacity: '0.5' } : { opacity: '1' }}>
                <ToastContainer />
                <section className="wrapper">

                    <div className="row">
                        <div className="col-lg-12">
                            <span className='p-3'>SEO</span>
                            <section className="card m-3">



                                <div className="main">
                                    <DataTables data={banner} columns={tableData.columns} />

                                </div>
                            </section>

                        </div>

                    </div>
                </section>
            </section>




            {/* update */}
            <Modal show={showUpdate} onHide={handleUpdate} style={{ zIndex: "9999" }}>
                <Modal.Header closeButton>
                    <Modal.Title>Update SEO</Modal.Title>
                </Modal.Header>
                <Form>
                    <Modal.Body>



                        <Form.Group className="col-md-12 pb-5">
                            <Form.Label>Seo Title</Form.Label>
                            <Form.Control
                                value={updatedTitle}
                                onChange={(e) => setUpdatedTitle(e.target.value)}
                            />
                        </Form.Group>


                        <Form.Group className="col-md-12 pb-5">
                            <Form.Label>Seo Description</Form.Label>
                            <Form.Control as="textarea" rows={3}
                                value={updatedDescription}
                                onChange={(e) => setUpdatedDescription(e.target.value)}
                            />
                        </Form.Group>


                        <Form.Group className="col-md-12 pb-5">
                            <Form.Label>Seo canonical</Form.Label>
                            <Form.Control
                                value={UpdatedCanonical}
                                onChange={(e) => setUpdatedCanonical(e.target.value)}
                            />
                        </Form.Group>

                        <Form.Group className="col-md-12 pb-5">
                            <Form.Label>Seo Feature-Image url</Form.Label>
                            <Form.Control
                                value={UpdatedFeature}
                                onChange={(e) => setUpdatedFeature(e.target.value)}
                            />
                        </Form.Group>


                        <Form.Group className="col-md-12 pb-3">
                            <Form.Select aria-label="Default select example"
                                value={UpdatedRobot}
                                onChange={(e) => setUpdatedRobot(e.target.value)}
                            >
                                <option value="1">Index/Follow</option>
                                <option value="2">Index/No-follow</option>
                                <option value="3">No-index/Follow</option>
                                <option value="4">No-index,No-Follow</option>
                            </Form.Select>
                        </Form.Group>


                        <Form.Group className="col-md-12 pb-5">
                            <Form.Label>Seo Script</Form.Label>
                            <Form.Control as="textarea" rows={3}
                                value={UpdatedScript}
                                onChange={(e) => setUpdatedScript(e.target.value)}
                            />
                        </Form.Group>


                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                        <Button className="Add_btn_" onClick={onUpdate}>
                            Update
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>



        </>
    )
}

export default SEO