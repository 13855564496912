import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Form from 'react-bootstrap/Form';
import { useForm } from "react-hook-form";
import { Button } from 'react-bootstrap';
import Spinner from 'react-bootstrap/Spinner';
import { useParams } from 'react-router-dom';
import TextEditor from '../Editor/TextEditor';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { GrDocumentDownload } from 'react-icons/gr';
import { Helmet } from 'react-helmet';

const UpdateProduct = () => {

    const { id } = useParams();
    const [products, setProducts] = useState([]);
    const [pending, setPending] = useState(true);
    const [productPdf, setProductPdf] = useState('');


    const [name, setServicename] = useState('');
    const [title, setServicetitle] = useState('');
    const [short_description, setServiceshort] = useState('');
    const [description, setServicedescription] = useState('');
    const [image, setServiceimage] = useState('');
    const [banner_title, setSerivebannertitle] = useState('');
    const [banner_description, setSerivebannerdescription] = useState('');
    const [banner_image, setSerivebannerimage] = useState('');




    const [updatedImage, setUpdatedImage] = useState('');
    const [updatedbannerImage, setUpdatedbannerImage] = useState('');
    const [updatedListtitle, setUpdatedListtitle] = useState('');
    const [updatedListdescription, setUpdatedListdescription] = useState('');

    const [updatedTitle, setUpdatedTitle] = useState('');
    const [updatedDescription, setUpdatedDescription] = useState('');
    const [UpdatedCanonical, setUpdatedCanonical] = useState('');
    const [UpdatedRobot, setUpdatedRobot] = useState('');
    const [UpdatedScript, setUpdatedScript] = useState('');
    const [UpdatedFeature, setUpdatedFeature] = useState('');



    const notify = () => toast.info("Update SuccessFully", { position: "top-center" });

    const getProducts = () => {
        axios.get(`/getsingleService/${id}`).then((response) => {

            if (response.status === 200) {
                setPending(false);
                console.log(response.data.data);
                setProducts(response.data.data);
            }
        }).catch((err) => {
            setPending(false);
            console.log(err);
        })
    }

    useEffect(() => {
        getProducts();
        window.scrollTo(0, 0);
    }, []);



    useEffect(() => {
        products?.map((product) => {
            // productImg = product.image;
          
            setProductPdf(product.pdf);
          


            setServicename(product.name);
            setServicetitle(product.title);
            setUpdatedListtitle(product.list_title);
            setUpdatedListdescription(product.list_description);

            setServiceshort(product.short_description);
            setServicedescription(product.description);
            setServiceimage(product.image);
            setSerivebannertitle(product.banner_title);
            setSerivebannerdescription(product.banner_description);
            setSerivebannerimage(product.banner_image);

            setUpdatedTitle(product.seo_title);
            setUpdatedDescription(product.seo_description);
            setUpdatedCanonical(product.canonical);
            setUpdatedRobot(product.robot);
            setUpdatedScript(product.script);
            setUpdatedFeature(product.feature_image);



        })

    }, [products])

    const { register, handleSubmit, formState: { errors } } = useForm();
    const onSubmit = () => {
        setPending(true);
        const formData = new FormData();
        formData.append("file", updatedImage);
        formData.append("pdf", productPdf);


        axios.post('/updateService', {
            id:id,
            name:name,
            title:title,
            short_description:short_description,
            description:description,
            image:updatedImage,
            banner_title:banner_title,
            banner_description:banner_description,
            banner_image:updatedbannerImage,
            list_title:updatedListtitle,
            list_description:updatedListdescription,


            seo_title: updatedTitle,
            seo_description: updatedDescription,
            canonical: UpdatedCanonical,
            robot: UpdatedRobot,
            script: UpdatedScript,
            feature_image: UpdatedFeature


        }, {
            method: "POST",
            headers: {
                "Content-Type": "multipart/form-data"
            },
        }).then((response) => {
            if (response.status === 200) {
                notify();
                setPending(false);
                
                setTimeout(() => {
                    window.location.reload(true);
                  }, 2000);

            }
        }).catch((err) => {
            setPending(false);
            console.log(err);
        })
    }
    const [file, setFile] = useState();
    function handleChange(e) {
        console.log(e.target.files);
        setFile(URL.createObjectURL(e.target.files[0]));
    }




    useEffect(() => {

    }, []);

    return (

        <>

            <Helmet>
                <title>KPHE | Products</title>
            </Helmet>

            <Spinner animation="border" className={pending ? 'mySpinner' : 'd-none'} />
            <section id="main-content" style={pending ? { opacity: '0.5' } : { opacity: '1' }}>
                <section class="wrapper">
                    <div class="row">
                    <ToastContainer />
                        <div class="col-lg-12">
                            <span>Update Service</span>
                            <section class="card cardSectionBorder mt-3">

                                <div class="card-body">
                                    <Form className='row' onSubmit={handleSubmit(onSubmit)}>
                                        <Form.Group className="col-md-6 pb-5" >
                                            <Form.Label>Service name</Form.Label>
                                            <Form.Control type="text"
                                                value={name}
                                                onChange={(e) => setServicename(e.target.value)}
                                            />
                                        </Form.Group>

                                        <Form.Group className="col-md-6 pb-5" >
                                            <Form.Label>Service Title</Form.Label>
                                            <Form.Control type="text"
                                                value={title}
                                                onChange={(e) => setServicetitle(e.target.value)}
                                            />
                                        </Form.Group>


                                        <div className='col-lg-12 pb-5'>
                                            <Form.Label>Service Short Description</Form.Label>
                                            <TextEditor data={short_description} setUpdatedContent={setServiceshort} />
                                        </div>
                                        <div className='col-lg-12 pb-5'>
                                            <Form.Label>Service Description</Form.Label>
                                            <TextEditor data={description} setUpdatedContent={setServicedescription} />
                                        </div>

                                        <Form.Group className="col-md-6 pb-5" >
                                            <Form.Label>List Title</Form.Label>
                                            <Form.Control type="text"
                                                value={updatedListtitle}
                                                onChange={(e) => setUpdatedListtitle(e.target.value)}
                                            />
                                        </Form.Group>

                                        <Form.Group className="col-md-6 pb-5" >
                                            <Form.Label>List Description</Form.Label>
                                            <Form.Control type="text"
                                                value={updatedListdescription}
                                                onChange={(e) => setUpdatedListdescription(e.target.value)}
                                            />
                                        </Form.Group>

                                        { <Form.Group className="col-md-6 pb-5">
                                            <Form.Label>Image</Form.Label>
                                            <Form.Control type="file"
                                                onChange={(e) => setUpdatedImage(e.target.files[0])}
                                            />

                                        </Form.Group> }
                                        {<div className='col-lg-6 text-center'>
                                            <Form.Label>Image Preview</Form.Label>
                                            <div className='p-3'>

                                                {updatedImage == "" ?

                                                    <img src={`/assets/service/${image}`} style={{ maxWidth: "300px", maxHeight: "300px" }} />
                                                    :
                                                    <img src={URL.createObjectURL(updatedImage)} style={{ maxWidth: "300px", maxHeight: "300px" }} />
                                                }
                                            </div>
                                        </div>
                                         }

                                         <Form.Group className="col-md-6 pb-5" >
                                                <Form.Label>Banner Title</Form.Label>
                                                <Form.Control type="text"
                                                    value={banner_title}
                                                    onChange={(e) => setSerivebannertitle(e.target.value)}
                                                />
                                            </Form.Group>
                                        


                                            <Form.Group className="col-md-6 pb-5" >
                                                <Form.Label>banner Description</Form.Label>
                                                <Form.Control type="text"
                                                    value={banner_description}
                                                    onChange={(e) => setSerivebannerdescription(e.target.value)}
                                                />
                                            </Form.Group>

                                            { <Form.Group className="col-md-6 pb-5">
                                            <Form.Label>Banner Image</Form.Label>
                                            <Form.Control type="file"
                                                onChange={(e) => setUpdatedbannerImage(e.target.files[0])}
                                            />

                                        </Form.Group> }
                                        {<div className='col-lg-6 text-center'>
                                            <Form.Label>Banner Image Preview</Form.Label>
                                            <div className='p-3'>

                                                {updatedbannerImage == "" ?

                                                    <img src={`/assets/service/${banner_image}`} style={{ maxWidth: "300px", maxHeight: "300px" }} />
                                                    :
                                                    <img src={URL.createObjectURL(updatedbannerImage)} style={{ maxWidth: "300px", maxHeight: "300px" }} />
                                                }
                                            </div>
                                        </div>
                                         }


                                        <Form.Group className="col-md-12 pb-5">
                                            <Form.Label>Seo Title</Form.Label>
                                            <Form.Control
                                                value={updatedTitle}
                                                onChange={(e) => setUpdatedTitle(e.target.value)}
                                            />
                                        </Form.Group>


                                        <Form.Group className="col-md-12 pb-5">
                                            <Form.Label>Seo Description</Form.Label>
                                            <Form.Control as="textarea" rows={3}
                                                value={updatedDescription}
                                                onChange={(e) => setUpdatedDescription(e.target.value)}
                                            />
                                        </Form.Group>


                                        <Form.Group className="col-md-12 pb-5">
                                            <Form.Label>Seo canonical</Form.Label>
                                            <Form.Control
                                                value={UpdatedCanonical}
                                                onChange={(e) => setUpdatedCanonical(e.target.value)}
                                            />
                                        </Form.Group>

                                        <Form.Group className="col-md-12 pb-5">
                                            <Form.Label>Seo Feature-Image url</Form.Label>
                                            <Form.Control
                                                value={UpdatedFeature}
                                                onChange={(e) => setUpdatedFeature(e.target.value)}
                                            />
                                        </Form.Group>


                                        <Form.Group className="col-md-12 pb-3">
                                            <Form.Select aria-label="Default select example"
                                                value={UpdatedRobot}
                                                onChange={(e) => setUpdatedRobot(e.target.value)}
                                            >
                                                <option value="1">Index/Follow</option>
                                                <option value="2">Index/No-follow</option>
                                                <option value="3">No-index/Follow</option>
                                                <option value="4">No-index,No-Follow</option>
                                            </Form.Select>
                                        </Form.Group>


                                        <Form.Group className="col-md-12 pb-5">
                                            <Form.Label>Seo Script</Form.Label>
                                            <Form.Control as="textarea" rows={3}
                                                value={UpdatedScript}
                                                onChange={(e) => setUpdatedScript(e.target.value)}
                                            />
                                        </Form.Group>



                                        <div className='text-center'>
                                            <Button className='Add_btn_' type='submit'>
                                                Submit
                                            </Button>
                                        </div>
                                    </Form>
                                </div>

                            </section>
                        </div>
                    </div>
                </section>
            </section>
        </>
    )
}

export default UpdateProduct