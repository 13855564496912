import React, { useState, useEffect } from 'react'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import axios from 'axios';
import DataTables from '../DataTable/DataTables';
import { FaTrashAlt, FaEdit } from 'react-icons/fa';
import { useForm } from 'react-hook-form';
import Spinner from 'react-bootstrap/Spinner';
import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
import { Helmet } from 'react-helmet';
import TextEditor from '../Editor/TextEditor';


const TestimonialAdmin = () => {
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [updatedContent, setUpdatedContent] = useState('');
  const [content, setContent] = useState('');

  const [testimonial, setTestimonial] = useState([]);

  const [updateContent, setUpdateContent] = useState('');


  const handleShow = () => setShow(true);
  const handleShow1 = () => setShow1(true);

  const [pending, setPending] = useState(true);
  const [file, setFile] = useState('');
  const [name, setName] = useState('');
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [stars, setStars] = useState('');
  let validationRules = { Today: { required: true } };


  const [showUpdate, setShowUpdate] = useState(false);
  const [showUpdate1, setShowUpdate1] = useState(false);


  const [data, setData] = useState([]);
  const [updatedName, setUpdatedName] = useState('');
  const [updatedTitle, setUpdatedTitle] = useState('');
  const [updatedDescription, setUpdatedDescription] = useState('');
  const [updatedStars, setUpdatedStars] = useState('');



  const [Id, setId] = useState('');

  const handleUpdate = () => setShowUpdate(false);
  const handleUpdate1 = () => setShowUpdate1(false);


  const handleClose = () => {
    setShow(false);
    setShow1(false);

    setFile('');
  };

  const getTestimonial = () => {
    axios.get('/getadmintestimonial').then((response) => {
      setTestimonial(response.data.data);
      setPending(false);
    }).catch((err) => {
      setPending(false);
      console.log(err);
    })
  }

  const getTestimonialcontent = () => {
    axios.get('/getadmintestimonialcontent').then((response) => {
      setContent(response.data.data);
      setPending(false);
    }).catch((err) => {
      setPending(false);
      console.log(err);
    })
  }

  useEffect(() => {
    getTestimonial();
    getTestimonialcontent();
  }, []);


  useEffect(() => {
    setUpdatedName(data[0]?.name);
    setId(data[0]?.id);
    setUpdatedTitle(data[0]?.title);
    setUpdatedDescription(data[0]?.description);
    setUpdatedStars(data[0]?.stars);


  }, [data]);


  const notifyUpdate = () => toast.info('Testimonial update Successfully', {
    position: "top-center",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });

  const notifyUpdate1 = () => toast.info('Testimonial update Successfully', {
    position: "top-center",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });
  const notify = () => toast.info('Testimonial add Successfully', {
    position: "top-center",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });

  const addTestimonialSuccess = () => toast.info("Testimonial Added Successfully", { position: "top-center" });
  const removeTestimonialSuccess = () => toast.info("Testimonial Removed Successfully", { position: "top-center" });
  const notifyStatus = () => toast.info("Testimonial Status Change Successfully", { position: "top-center" });
  const { register, handleSubmit, formState: { errors } } = useForm();
  const onSubmit = () => {
    const formData = new FormData();
    formData.append("file", file);

    axios.post('/addTestimonial', {

      image: file,
      name: name,
      title: title,
      description: description,
      stars: stars


    }, {
      method: 'POST',
      headers: {
        "Content-Type": "multipart/form-data"
      },

    }).then((response) => {
      if (response.status === 200) {
        handleClose();
        setTimeout(() => {
          window.location.reload(true);
        }, 2000);
     
        addTestimonialSuccess();
      }
    }).catch((err) => {

      console.log(err);
    })
  }

  const deleteTestimonial = (id) => {

    axios.post('/deleteTestimonial', { id: id }, {
      method: 'POST',
      headers: {
        "Content-Type": "multipart/form-data"
      },
    }).then((response) => {
      removeTestimonialSuccess();
      setTimeout(() => {
        window.location.reload(true);
      }, 2000);
    }).catch((err) => {

      console.log(err);
    })
  }

  const handleStatus = (e) => {
    let id = e.target.id;
    let status = e.target.value;
    axios.post('/updatetestimonialStatus', {
      id: id,
      status: status,
    }, {
      method: "POST",
      headers: {
        "Content-Type": "multipart/form-data"
      }
    }).then((response) => {
      if (response.status === 200) {
        setTimeout(() => {
          window.location.reload(true);
        }, 2000);
        notifyStatus();
      }
    }).catch((err) => {

      console.log(err);
    })


  }


  const handleShowUpdate = (id) => {
    setShowUpdate(true);
    // getProductName();
    axios.get(`/getadmintestimonial/${id}`).then((response) => {
      setData(response.data.data);
    }).catch((err) => {

      console.log(err);
    })

  }


  const onUpdate = () => {
    setPending(true);
    const formData = new FormData();
    axios.post('/updateTestimonial', {
      id: Id,
      name: updatedName,
      title: updatedTitle,
      description: updatedDescription,
      stars: updatedStars
    }, {
      method: 'POST',
      headers: {
        "Content-Type": "multipart/form-data"
      }
    }).then((response) => {
      if (response.status === 200) {
        notifyUpdate();
        handleClose();
        setTimeout(() => {
          window.location.reload(true);
        }, 2000);

      }
    }).catch((err) => {

      console.log(err);
    })


  }


  const onUpdate1 = () => {
    setPending(true);
    const formData = new FormData();
    axios.post('/updateTestimonialcontent', {
      id: 1,
      content: updatedContent,
    }, {
      method: 'POST',
      headers: {
        "Content-Type": "multipart/form-data"
      }
    }).then((response) => {
      if (response.status === 200) {
        notifyUpdate1();
        handleClose();

        setTimeout(() => {
          window.location.reload(true);
        }, 2000);

      }
    }).catch((err) => {

      console.log(err);
    })


  }

  const tableData = {
    columns: [
      {
        name: '#',
        cell: (row, index) => index + 1

      },
      {
        name: "Name",
        selector: (row) => row.name,
        width: '200px',
        sortable: true
      },
      {
        name: "Title",
        selector: (row) => row.title,
        width: '200px',
        sortable: true
      },
      {
        name: "Description",
        selector: (row) => row.description,
        width: '200px',
        sortable: true
      },
      {
        name: "Status",
        selector: row => <select className=" col-md-4 select_status"
          value={row.status}
          id={row.id}
          onChange={(e) => handleStatus(e)}
        >
          <option value="0">Active</option>
          <option value="1">In-Active</option>

        </select>,
        center: true,

        width: '200px',
      },
      {
        name: "Action",
        selector: row => <div className='d-flex' style={{ gap: '10px' }}>
          <Button className='Add_btn_' onClick={() => handleShowUpdate(row.id)}><FaEdit /></Button>
          <Button className='_delete_btn_' onClick={() => deleteTestimonial(row.id)} ><FaTrashAlt /></Button></div>,
        right: true,

        width: '200px',
      },

    ],

  };



  return (
    <>

      <Helmet>
        <title>KPHE | Testimonial</title>
      </Helmet>

      <Spinner animation="border" className={pending ? 'mySpinner' : 'd-none'} />
    
      <section id="main-content" style={pending ? { opacity: '0.5' } : { opacity: '1' }}>

        <section class="wrapper">
          <div class="row">
          <ToastContainer />
            <div class="col-lg-12">
              <span className=''>Testimonial</span>
              <section className='card mt-3'>



                <div className='addcareer pt-3 px-3'>

                  <Button className='Add_btn_' onClick={handleShow1}>
                    Update Content
                  </Button>

                  <Button className='Add_btn_' onClick={handleShow}>
                    Add Testimonial
                  </Button>
                </div>
                <div className='main'>
                  <DataTables data={testimonial} columns={tableData.columns} />
                </div>
              </section>
            </div>
          </div>

        </section>
      </section>


      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add Testimonials</Modal.Title>
        </Modal.Header>
        <ToastContainer />
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Body>



            <Form.Group className=" col-sm-12 mb-3">
            </Form.Group>
            <Form.Group className="col-md-12 pb-3">
              <Form.Label>Name</Form.Label>
              <Form.Control
                value={name} placeholder="Enter Name"
                {...register("name", {
                  required: true,
                  onChange: (e) => setName(e.target.value)
                })}
              />
              {errors.name && <div className='errMsg pt-2'>Please Provide Name</div>}
            </Form.Group>


            <Form.Group className=" col-sm-12 mb-3">
            </Form.Group>
            <Form.Group className="col-md-12 pb-3">
              <Form.Label>Title</Form.Label>
              <Form.Control
                value={title} placeholder="Enter Title"
                {...register("title", {
                  required: true,
                  onChange: (e) => setTitle(e.target.value)
                })}
              />
              {errors.title && <div className='errMsg pt-2'>Please Provide Title</div>}
            </Form.Group>

            <Form.Group className=" col-sm-12 mb-3">
            </Form.Group>
            <Form.Group className="col-md-12 pb-3">
              <Form.Label>Description</Form.Label>
              <Form.Control
                value={description} placeholder="Enter Description"
                {...register("description", {
                  required: true,
                  onChange: (e) => setDescription(e.target.value)
                })}
              />
              {errors.description && <div className='errMsg pt-2'>Please Provide Description</div>}
            </Form.Group>

            <Form.Group className=" col-sm-12 mb-3">
            </Form.Group>
            <Form.Group className="col-md-12 pb-3">
              <Form.Label>Stars</Form.Label>
              <Form.Control
                value={stars} placeholder="Enter Stars"
                {...register("stars", {
                  required: true,
                  onChange: (e) => setStars(e.target.value)
                })}
              />
              {errors.stars && <div className='errMsg pt-2'>Please Provide Stars</div>}
            </Form.Group>



          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button className='Add_btn_' type="submit">
              Submit
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>

      <Modal show={show1} onHide={handleClose} style={{ zIndex: "9999" }}>
        <Modal.Header closeButton>
          <Modal.Title>Update Testimonial Content</Modal.Title>
        </Modal.Header>
        <ToastContainer />
        <Form>
          <Modal.Body>

            <Form.Group className="col-md-12 pb-5">
              <Form.Label>Long Content</Form.Label>

              <div className='form-control'>
                <TextEditor data={content[0]?.content} setUpdatedContent={setUpdatedContent} validationRules={validationRules} />
              </div>
            </Form.Group>


          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button className="Add_btn_" onClick={onUpdate1}>
              Update
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>


      {/* update */}
      <Modal show={showUpdate} onHide={handleUpdate} style={{ zIndex: "9999" }}>
        <Modal.Header closeButton>
          <Modal.Title>Update Testimonial</Modal.Title>
        </Modal.Header>
        <ToastContainer />
        <Form>
          <Modal.Body>



            <Form.Group className=" col-sm-12 mb-3">
            </Form.Group>
            <Form.Group className="col-md-12 pb-3">
              <Form.Label>Name</Form.Label>
              <Form.Control
                value={updatedName} placeholder="Enter Name"
                {...register("name", {
                  required: true,
                  onChange: (e) => setUpdatedName(e.target.value)
                })}
              />
              {errors.name && <div className='errMsg pt-2'>Please Provide Name</div>}
            </Form.Group>


            <Form.Group className=" col-sm-12 mb-3">
            </Form.Group>
            <Form.Group className="col-md-12 pb-3">
              <Form.Label>Title</Form.Label>
              <Form.Control
                value={updatedTitle} placeholder="Enter Title"
                {...register("title", {
                  required: true,
                  onChange: (e) => setUpdatedTitle(e.target.value)
                })}
              />
              {errors.title && <div className='errMsg pt-2'>Please Provide Title</div>}
            </Form.Group>

            <Form.Group className=" col-sm-12 mb-3">
            </Form.Group>
            <Form.Group className="col-md-12 pb-3">
              <Form.Label>Description</Form.Label>
              <Form.Control
                value={updatedDescription} placeholder="Enter Description"
                {...register("description", {
                  required: true,
                  onChange: (e) => setUpdatedDescription(e.target.value)
                })}
              />
              {errors.description && <div className='errMsg pt-2'>Please Provide Description</div>}
            </Form.Group>

            <Form.Group className=" col-sm-12 mb-3">
            </Form.Group>
            <Form.Group className="col-md-12 pb-3">
              <Form.Label>Stars</Form.Label>
              <Form.Control
                value={updatedStars} placeholder="Enter Stars"
                {...register("stars", {
                  required: true,
                  onChange: (e) => setUpdatedStars(e.target.value)
                })}
              />
              {errors.stars && <div className='errMsg pt-2'>Please Provide Stars</div>}
            </Form.Group>


          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button className="Add_btn_" onClick={onUpdate}>
              Update
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>



    </>
  )
}

export default TestimonialAdmin