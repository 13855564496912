import { Route, Router, Routes, HashRouter } from 'react-router-dom';
import React, { Suspense, lazy } from 'react';
import Dashboard from './admin/components/Pages/Dashboard';
import Login from './admin/components/Login/Login';
import Adminabout from './admin/components/Pages/Adminabout';
import Profile from './admin/components/Login/Profile';
import Enquiries from '../components/admin/components/Pages/Enquiries';
import Products from './admin/components/Pages/Products';
import AddProducts from './admin/components/Pages/AddProducts';
import Setting from './admin/components/Pages/Setting';
import TestimonialAdmin from './admin/components/Pages/TestimonialAdmin';
import ClientsAdmin from './admin/components/Pages/ClientsAdmin';
import UpdateProduct from './admin/components/Pages/UpdateProduct';
import GalleyAdmin from './admin/components/Pages/GalleryAdmin';
import Adminblog from './admin/components/Pages/Blog';
import Adminwhychoose from './admin/components/Pages/Adminwhychoose';
import Servicechecklist from './admin/components/Pages/Servicechecklist';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../components/admin/components/AdminApp.css'
import SEO from './admin/components/Pages/Seo';
import { useSelector } from 'react-redux';
import ProtectedRoute from './admin/components/ProtectedRoute';
import Backend from '../components/admin/components/Backend';



function App() {

  const { auth } = useSelector((state) => state?.auth);

  return (
    <>
      <React.Fragment>
        <HashRouter>
          <Routes>
          {auth ?

            <Route path='/' element={<ProtectedRoute><Backend /></ProtectedRoute>}>
              <Route index element={<Dashboard />} />
              <Route exact path='/profile' element={<Profile />} />
              <Route exact path='/adminabout' element={<Adminabout />} />
              <Route exact path='/enquiry' element={<Enquiries />} />
              <Route exact path='/service' element={<Products />} />
              <Route exact path="/service/addservice" element={<AddProducts />} />
              <Route exact path="/gallery" element={<GalleyAdmin />} />
              <Route exact path="/setting" element={<Setting />} />
              <Route exact path="/seo" element={<SEO />} />
              <Route exact path='/testimonials' element={<TestimonialAdmin />} />
              <Route exact path='/clients' element={<ClientsAdmin />} />
              <Route exact path='/service/updateservice/:id' element={<UpdateProduct />} />
              <Route exact path='/blog' element={<Adminblog />} />
              <Route exact path='/adminwhychoose' element={<Adminwhychoose />} />
              <Route exact path='/servicechecklist' element={<Servicechecklist />} />
 

            </Route>
            :
            <Route exact path='/' element={<Login />} />

          }
          </Routes>



        </HashRouter>
      </React.Fragment >


       
    

  
    </>
  );
}

export default App;
