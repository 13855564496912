import { AiFillDashboard ,AiFillSetting} from 'react-icons/ai';
import { IoImageSharp } from 'react-icons/io5';
import { FaMicroblog ,FaBlogger} from 'react-icons/fa';
import { RiPsychotherapyFill ,RiQuestionAnswerLine } from 'react-icons/ri';
import { MdFmdBad, MdContactPhone, MdGroups2, MdMarkEmailUnread } from 'react-icons/md';
import { SiServerless } from 'react-icons/si';
import { BiUserCheck } from 'react-icons/bi';
import {BsFillGridFill} from 'react-icons/bs'
import { FaBookReader } from 'react-icons/fa'
export const SidebarItems = [
    {
        title: "Dashboard",
        url: "/",
        icon: <AiFillDashboard />,
    },
    {
        title: "About Us",
        url: "/adminabout",
        icon: <FaBookReader />
    },
    {
        title: "Why Choose Us",
        url: "/adminwhychoose",
        icon: <RiQuestionAnswerLine />
    },
    {
        title: "Testimonials",
        url: "/testimonials",
        icon: <FaMicroblog />,
    },
    {
        title: "Services",
        url: "/service",
        icon: <SiServerless />,
    },
    {
        title: "Enquiry",
        url: "/enquiry",
        icon: <MdMarkEmailUnread />
    },
    {
        title: "Banners",
        url: "/gallery",
        icon: <BsFillGridFill />,
    },
    {
        title: "Blogs",
        url: "/blog",
        icon: <FaBlogger />
    },
    {
        title: "seo",
        url: "/seo",
        icon: <AiFillSetting />
    },
    {
        title: "Setting",
        url: "/setting",
        icon: <AiFillSetting />
    },
    {
        title: "Service Checklist",
        url: "/servicechecklist",
        icon: <AiFillSetting />
    },

]

