import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    adminAuth: "",
    auth: ""
}

const adminSlice = createSlice({
    name: "admin",
    initialState: initialState,
    reducers: {
        setAdmin: (state, action) => {
            state.adminAuth = action.payload.adminAuth
            state.auth = action.payload.auth
        },
        logout: (state) => {
            localStorage.removeItem('auth')
            localStorage.removeItem('adminAuth')
            state.adminAuth = null
            state.auth = null
        }
    }
})

export const { setAdmin, logout } = adminSlice.actions
export const { reducer: authReducer } = adminSlice;

export const selectCurrentAdmin = (state) => state.admin.adminAuth;