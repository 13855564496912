import axios from 'axios';
import React, { useState, useEffect } from 'react'
import Modal from 'react-bootstrap/Modal';
import DataTables from '../DataTable/DataTables';
import Spinner from 'react-bootstrap/Spinner';
import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';
import { FaTrashAlt, FaEdit, FaFilePdf } from 'react-icons/fa';
import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
import { Helmet } from "react-helmet";

const Products = () => {

    const [show, setShow] = useState(false);
    const handleClose = () => {
        setShow(false);

    };
    const [products, setProducts] = useState([]);
    const [pending, setPending] = useState(true);
    const [deleteId, setDeleteId] = useState('');
    const [newData, setNewData] = useState([]);

    const getProducts = () => {
        axios.get('/getadminService').then((response) => {
            console.log(response.data.data);
            setProducts(response.data.data);
            setNewData(response.data.data);
            setPending(false);
        }).catch((err) => {
            setPending(false);
            console.log(err);
        })
    }

    const notify = () => toast.info("Service Delete SuccessFully", { position: "top-center" });
    const notifystatus = () => toast.info("Service Status Change SuccessFully", { position: "top-center" });
    
    useEffect(() => {
        getProducts();
    }, []);



    const handleShow = (e) => {
        setShow(true);
        setDeleteId(e);

    };

    const deleteProduct = () => {
        axios.post('/deleteService  ', { id: deleteId }, {
            method: "POST",
            headers: {
                "Content-Type": "multipart/form-data"
            }
        }).then((response) => {
            if (response.status === 200) {
                handleClose();
                notify();
                setTimeout(() => {
                    window.location.reload(true);
                  }, 2000);
            }
        }).catch((err) => {

            console.log(err);
        })

    }

    const handleStatus = (e) => {
        let id = e.target.id;
        let status = e.target.value;
        axios.post('/updateserviceStatus', {
            id: id,
            status: status,
        }, {
            method: "POST",
            headers: {
                "Content-Type": "multipart/form-data"
            }
        }).then((response) => {
            if (response.status === 200) {
                notifystatus();
                setTimeout(() => {
                    window.location.reload(true);
                  }, 2000);
            }
        }).catch((err) => {

            console.log(err);
        })
    }


    const tableData = {
        columns: [
            {
                name: '#',
                cell: (row, index) => index + 1
            },
            {
                name: "Title",
                selector: (row) => row.title,
                sortable: true
            },
            {
                name: "Type",
                selector: (row) => row.type==0 ? 'Main' : 'Other' ,
                sortable: true
            },
            {
                name: "Image",
                selector: (row) => <img width="50px" height="50px" className='p-2' src={`/assets/service/${row.image}`} />,
                sortable: true,
            },
            {
                name: "Status",
                selector: row => <select className=" col-md-4 select_status"
                    value={row.status}
                    id={row.id}
                    onChange={(e) => handleStatus(e)}
                >
                    <option value="0">Active</option>
                    <option value="1">In-Active</option>

                </select>,
                center: true,
            },
            {
                name: "Action",
                selector: row => <div className='d-flex' style={{ gap: '10px' }}>
                    <Link to={`/service/updateservice/${row.id}`}><Button className='_edit_icons'><FaEdit /></Button></Link>
                    <Button className='_delete_btn_' onClick={() => handleShow(row.id)}><FaTrashAlt /></Button></div>,
                right: true
            },

        ],

    };

    const filterByStatus = (inputValue) => {

        if (inputValue == "") {
            setProducts(newData);
        } else {
            setProducts(newData?.filter((data) => {
                return (
                    Object.values(data).join('').toLowerCase().includes(inputValue.toLowerCase())
                )
            }))
        }

    }





    return (
        <>

            <Helmet>
                <title>KPHE | Services</title>
            </Helmet>

            <Spinner animation="border" className={pending ? 'mySpinner' : 'd-none'} />
            <section id="main-content" style={pending ? { opacity: '0.5' } : { opacity: '1' }}>
                <section class="wrapper">
                    <div class="row">
                    <ToastContainer />
                        <div class="col-lg-12">
                            <span className=''>Services</span>
                            <section class="card mt-3">
                                <div className='search_d'>
                                    <div className='p-3'>

                                    </div>

                                    <div className='addcareer p-2'>
                                        <Link to='/service/addservice'><Button className='Add_btn_'>
                                            Add Service
                                        </Button></Link>
                                    </div>
                                </div>


                                <div className="main">
                                    <DataTables data={products} columns={tableData.columns}  />

                                </div>
                            </section>

                        </div>

                    </div>
                </section>
            </section>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>

                </Modal.Header>
                <Modal.Body><Modal.Title>Are you sure you want to delete this Service ?</Modal.Title></Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="danger" onClick={() => deleteProduct()}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>

        </>
    )
}

export default Products