import { useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import { useForm } from 'react-hook-form';
import Logo from '../Assets/logo-1.png';
import { FaUserAlt, FaEye, FaEyeSlash } from 'react-icons/fa';
import axios from 'axios';
import { RiLockPasswordFill } from 'react-icons/ri'
import { Button } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { Helmet } from "react-helmet";
import { useDispatch } from 'react-redux';
import { setAdmin } from '../../../store/features/authSlice';


const Login = () => {

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState("");
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
    setFEmail('');
  }
  const handleShow = () => setShow(true);
  const [authDetails, setAuthDetails] = useState([]);
  const [fEmail, setFEmail] = useState('');
  const [fEmailErr, setFEmailErr] = useState('');

  const notify = () => toast.info('Successfully Login', {
    position: "top-center",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });

  const notify1 = () => toast.info('Successfully Reset Password', {
    position: "top-center",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });
  const loginError = (err) => toast.error(`${err}`, {
    position: "top-center",
    autoClose: 5023,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });



  const { register, handleSubmit, formState: { errors } } = useForm();


  const onSubmit = () => {
    axios.post('/adminLogin', { email: email, password: password }, {
      method: 'POST',
      headers: {
        "Content-Type": "multipart/form-data"
      }
    }).then((response) => {
      if (response.status === 200) {
        setAuthDetails(response.data.data);
        localStorage.setItem("adminAuth", JSON.stringify(response.data.data));
        dispatch(setAdmin({ adminAuth: JSON.stringify(response.data.data), auth: true }))
        notify();
        // navigate('/');
        // window.location.reload(false);
      }

    }).catch((err) => {
      loginError(err.response.data.message);
    })

  }

  const handleForgetPass = () => {
    if (fEmail == "") {
      setFEmailErr('Please Enter email address');
    } else {
      submitForgetPass();
    }

  }

  const submitForgetPass = () => {
    axios.post('/forgotPass', {
      email: fEmail,
    }, {
      method: 'POST',
      headers: {
        "Content-Type": "multipart/form-data"
      }
    }).then((response) => {
      if (response.status === 200) {
        console.log(response.data.data);
        handleClose();
        notify1();

      }
    }).catch((err) => {

      console.log(err);
    })

  }



  return (
    <>

      <Helmet>
        <title>KPHE | admin</title>
      </Helmet>
      
      <div className='_bg'>
        <div className=" main_div">
          <ToastContainer />
          <div className="form-box">
            <div className="header-form text-center mb-3">
              <img src={Logo}  />
            </div>
            <div className="body-form">


              <Form className='adminlogin' onSubmit={handleSubmit(onSubmit)}>
                <Form.Group className="input-group" controlId="formBasicEmail">
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="basic-addon1"><FaUserAlt /></span>
                  </div>
                  <Form.Control type="email" placeholder="Enter email" 
                    value={email}
                    {...register("email", {
                      required: true,
                      onChange: (e) => setEmail(e.target.value)
                    })}
                  />

                </Form.Group>
                <small className="text-danger">
                  {errors?.email && <span>Please Enter Your Email Address</span>}
                </small>

                <Form.Group className="mt-3 input-group" controlId="formBasicPassword">
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="basic-addon1"><RiLockPasswordFill /></span>
                  </div>
                  <Form.Control type={showPassword ? "text" : "password"} placeholder="Password" autocomplete="new-password"
                    {...register("password", {
                      required: true,
                      onChange: (e) => setPassword(e.target.value)
                    })}
                    value={password}
                  />
                  <div className='input-group-append' style={{ position: 'absolute', right: '0', zIndex: '1000' }}
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    <span class="input-group-text" style={{ cursor: 'pointer' }} id="basic-addon1">  {showPassword ? <FaEye /> : <FaEyeSlash />}</span>

                  </div>
                </Form.Group>
                <small className="text-danger">
                  {errors?.password && <span>Please Enter Your Password</span>}
                </small>

                <div className='login_btn mt-3'>
                  <Button type="submit" className="btn btn-secondary btn-block">SIGN IN</Button>
                </div>

                <div className='forpass text-center'><p style={{ cursor: 'pointer' }} onClick={handleShow}>Forgot your password ?</p></div>
              </Form>

            </div>

            <Modal show={show} onHide={handleClose}>
              <Modal.Header closeButton className='adminpass'>
                <Modal.Title>Forget Your Password</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <form>
                  <div className="input-group mb-1">
                    <input type="email" className="form-control user" placeholder="Enter Your Email" name="Email"
                      value={fEmail}
                      onChange={(e) => setFEmail(e.target.value)}

                    />
                    <small className="text-danger">{fEmailErr}</small>

                  </div>

                  <div>
                    <Button onClick={handleForgetPass} className="submit_btn_"

                    >Submit</Button>
                  </div>

                </form>
              </Modal.Body>

            </Modal>
          </div>
        </div>
      </div>

    </>
  )
}

export default Login

