import React, { useState, useEffect } from 'react'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import axios from 'axios';
import DataTables from '../DataTable/DataTables';
import { FaTrashAlt, FaEdit } from 'react-icons/fa';
import { useForm } from 'react-hook-form';
import Spinner from 'react-bootstrap/Spinner';
import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
import { Helmet } from 'react-helmet';
import TextEditor from '../Editor/TextEditor';


const Servicechecklist = () => {
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [updatedContent, setUpdatedContent] = useState('');
  const [content, setContent] = useState('');

  const [testimonial, setTestimonial] = useState([]);



  const handleShow = () => setShow(true);
  const handleShow1 = () => setShow1(true);
  const [allProducts, setAllProducts] = useState([]);

  const [pending, setPending] = useState(true);
  const [file, setFile] = useState('');
  const [name, setName] = useState('');
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [stars, setStars] = useState('');
  let validationRules = { Today: { required: true } };


  const [showUpdate, setShowUpdate] = useState(false);

  const [product, setProduct] = useState('');

  const [data, setData] = useState([]);
  const [updatedName, setUpdatedName] = useState('');
  const [updatedTitle, setUpdatedTitle] = useState('');
  const [updatedDescription, setUpdatedDescription] = useState('');
  const [updatedStars, setUpdatedStars] = useState('');



  const [Id, setId] = useState('');

  const handleUpdate = () => setShowUpdate(false);


  const handleClose = () => {
    setShow(false);
    setShow1(false);

    setFile('');
  };

  const getTestimonial = () => {
    axios.get('/getadminServicelist').then((response) => {
      setTestimonial(response.data.data);
      setPending(false);
    }).catch((err) => {
      setPending(false);
      console.log(err);
    })

    axios.get('/getcardServices').then((response) => {
      console.log(response.data.data)
      setAllProducts(response.data.data);
    }).catch((err) => {
      console.log(err)
    });

  }



  const getTestimonialcontent = () => {
    axios.get('/getadmintestimonialcontent').then((response) => {
      setContent(response.data.data);
      setPending(false);
    }).catch((err) => {
      setPending(false);
      console.log(err);
    })
  }

  useEffect(() => {
    getTestimonial();
    getTestimonialcontent();
  }, []);


  useEffect(() => {
    setId(data[0]?.id);
    setUpdatedTitle(data[0]?.list);


  }, [data]);


  const notifyUpdate = () => toast.info('Service Check list update Successfully', {
    position: "top-center",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });


  const notify = () => toast.info('Service Check list add Successfully', {
    position: "top-center",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });

  const addTestimonialSuccess = () => toast.info("Testimonial Added Successfully", { position: "top-center" });
  const removeTestimonialSuccess = () => toast.info("Testimonial Removed Successfully", { position: "top-center" });

  const { register, handleSubmit, formState: { errors } } = useForm();
  const onSubmit = () => {
    const formData = new FormData();
    formData.append("file", file);

    axios.post('/addServicelist', {

      list: title,
      service_id: product,


    }, {
      method: 'POST',
      headers: {
        "Content-Type": "multipart/form-data"
      },

    }).then((response) => {
      if (response.status === 200) {
        handleClose();
        addTestimonialSuccess();
        // window.location.reload(false);

      }
    }).catch((err) => {

      console.log(err);
    })
  }

  const deleteTestimonial = (id) => {

    axios.post('/deleteServicelist', { id: id }, {
      method: 'POST',
      headers: {
        "Content-Type": "multipart/form-data"
      },
    }).then((response) => {
      removeTestimonialSuccess();
      window.location.reload(false);
    }).catch((err) => {

      console.log(err);
    })
  }



  const handleShowUpdate = (id) => {
    setShowUpdate(true);
    // getProductName();
    axios.get(`/getadminServicelist/${id}`).then((response) => {
      setData(response.data.data);
    }).catch((err) => {

      console.log(err);
    })

  }


  const onUpdate = () => {
    setPending(true);
    const formData = new FormData();
    axios.post('/updateServicelist', {
      id: Id,
      list: updatedTitle,
    }, {
      method: 'POST',
      headers: {
        "Content-Type": "multipart/form-data"
      }
    }).then((response) => {
      if (response.status === 200) {
        notifyUpdate();
        handleClose();

        window.location.reload(true);

      }
    }).catch((err) => {

      console.log(err);
    })


  }


  const onUpdate1 = () => {
    setPending(true);
    const formData = new FormData();
    axios.post('/updateServicelist', {
      id: 1,
      content: updatedContent,
    }, {
      method: 'POST',
      headers: {
        "Content-Type": "multipart/form-data"
      }
    }).then((response) => {
      if (response.status === 200) {
        handleClose();

        window.location.reload(true);

      }
    }).catch((err) => {

      console.log(err);
    })


  }

  const tableData = {
    columns: [
      {
        name: '#',
        cell: (row, index) => index + 1

      },
      {
        name: "Service",
        selector: (row) => row.service_name,
        width: '300px',
        sortable: true
      },
      {
        name: "Title",
        selector: (row) => row.list,
        width: '600px',
        sortable: true
      },
      {
        name: "Action",
        selector: row => <div className='d-flex' style={{ gap: '10px' }}>
          <Button className='Add_btn_' onClick={() => handleShowUpdate(row.id)}><FaEdit /></Button>
          <Button className='_delete_btn_' onClick={() => deleteTestimonial(row.id)} ><FaTrashAlt /></Button></div>,
        right: true,

      },

    ],

  };



  return (
    <>


      <Helmet>
        <title>KPHE | Service checklist</title>
      </Helmet>

      <Spinner animation="border" className={pending ? 'mySpinner' : 'd-none'} />
      <ToastContainer />
      <section id="main-content" style={pending ? { opacity: '0.5' } : { opacity: '1' }}>

        <section class="wrapper">
          <div class="row">
            <div class="col-lg-12">
              <span className='p-3'>Service checklist</span>
              <section className='card m-3'>



                <div className='addcareer p-2'>

                  <Button className='Add_btn_' onClick={handleShow}>
                    Add Checklist
                  </Button>
                </div>

                <DataTables data={testimonial} columns={tableData.columns} />
              </section>
            </div>
          </div>

        </section>
      </section>


      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add Checklist</Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Body>






            <Form.Group className="col-lg-12 col-md-6 col-sm-12 mb-3">
              <Form.Label>Service</Form.Label>
              <Form.Select
                value={product}
                {...register("product", {
                  onChange: (e) => setProduct(e.target.value),
                  required: 'Please select service',
                  pattern: {
                    message: "Please select service"
                  }
                })}

              >
                <option value="">Choose Service</option>
                {allProducts?.map((product) => {
                  return (

                    <option key={product.id} value={product.id}>{product.name}</option>


                  )
                })}
              </Form.Select>

              {errors.product && <span className='help-block with-errors text-danger'>{errors.product.message}</span>}

            </Form.Group>

            <Form.Group className=" col-sm-12 mb-3">
            </Form.Group>
            <Form.Group className="col-md-12 pb-3">
              <Form.Label>Name</Form.Label>
              <Form.Control
                value={title} placeholder="Enter Name"
                {...register("title", {
                  required: true,
                  onChange: (e) => setTitle(e.target.value)
                })}
              />
              {errors.title && <div className='errMsg pt-2'>Please Provide Name</div>}
            </Form.Group>

          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button className='Add_btn_' type="submit">
              Submit
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>



      {/* update */}
      <Modal show={showUpdate} onHide={handleUpdate} style={{ zIndex: "9999" }}>
        <Modal.Header closeButton>
          <Modal.Title>Update Service Checklist</Modal.Title>
        </Modal.Header>
        <Form>
          <Modal.Body>






            <Form.Group className=" col-sm-12 mb-3">
            </Form.Group>
            <Form.Group className="col-md-12 pb-3">
              <Form.Label>Title</Form.Label>
              <Form.Control
                value={updatedTitle} placeholder="Enter Title"
                {...register("title", {
                  required: true,
                  onChange: (e) => setUpdatedTitle(e.target.value)
                })}
              />
              {errors.title && <div className='errMsg pt-2'>Please Provide Title</div>}
            </Form.Group>




          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button className="Add_btn_" onClick={onUpdate}>
              Update
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>



    </>
  )
}

export default Servicechecklist